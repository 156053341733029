<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("exam_approval") }}
      <!-- 考试批阅 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="detailName"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="list-type-wrap">
      <ListType :checked="listType" @change="(type) => (listType = type)" />
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div
            class="content"
            :class="{ card: listType == 'card', list: listType == 'list' }"
          >
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <ExamReviewCard
                :newWindow="newWindow == 1"
                :listType="listType"
                :dataSource="item"
              />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { getReviewList } from "@/api/exam";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import ExamReviewCard from "@/components/new/mine/ExamReviewCard.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    ExamReviewCard,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      reviewStatus: [
        { id: 1, name: $t("LB_Teacher_HavaPerusal") }, // 已批阅
        { id: 2, name: $t("Pub_WithoutApproval") }, // 未批阅
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      status: 0,
      detailName: "",
      listType: "list",
      listLoading: true,
    });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.listType = company.useUserCenterShow == 2 ? "card" : "list";
      },
      { immediate: true }
    );

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      getReviewList({
        page: state.currentPage,
        pageSize: 15,
        detailName: state.detailName,
        status: state.status,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      switchStatus,
      pageChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
