<template>
  <div
    class="data-card"
    :class="{
      card: listType == 'card',
      list: listType == 'list',
      nojump: dataSource.status != 2,
    }"
    @click="jump"
  >
    <div class="tag" v-show="listType == 'card'">
      <span class="green" v-if="dataSource.status == 1">
        {{ $t("LB_Teacher_HavaPerusal")
        }}<!-- 已批阅 -->
      </span>
      <span class="red" v-else>
        {{ $t("Pub_WithoutApproval")
        }}<!-- 未批阅 -->
      </span>
    </div>
    <div class="cover">
      <img src="@/assets/image/mine/exam_review_cover.png" alt="exam" />
    </div>
    <div class="detail">
      <h5>
        <template v-if="listType == 'list'">
          <span class="status bg-green" v-if="dataSource.status === 1">
            {{ $t("LB_Teacher_HavaPerusal")
            }}<!-- 已批阅 -->
          </span>
          <span class="status bg-red" v-else>
            {{ $t("Pub_WithoutApproval")
            }}<!-- 未批阅 -->
          </span>
        </template>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.detailName }}
            </div>
          </template>
          {{ dataSource.detailName }}
        </a-popover>
      </h5>
      <div class="row mt12">
        {{ $t("Review_times") }}：<span class="blue">{{
          dataSource.totals - dataSource.waits
        }}</span
        >/{{ dataSource.totals }}
        <!-- 批阅次数 -->
      </div>
      <div class="row mt12">
        {{ $t("Pub_Lab_ExamSource") }}：{{
          `【${getTaskType(dataSource.taskType)}】 ${dataSource.taskName}`
        }}
        <!-- 考试来源 -->
      </div>
      <div
        class="row mt12"
        v-if="dataSource.taskType != 8 && dataSource.taskType != 6"
      >
        <span v-show="listType == 'list'">{{ $t("Pub_Lab_ExamTime") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/time.png"
          alt="time"
        /><span class="fs12"
          >{{ dateFormat(dataSource.startTime) }} ～
          {{ dateFormat(dataSource.endTime) }}</span
        >
        <!-- 考试时间 -->
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn" v-if="dataSource.status == 2">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { getTaskType } from "@/utils/business";
export default {
  name: "ExamReviewCard",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.dataSource.status != 2) return;
      if (props.newWindow) {
        window.open(
          `/exam/review/edit?ddtab=true&taskId=${props.dataSource.taskId}&detailId=${props.dataSource.detailId}&reexamId=${props.dataSource.reexamId}`
        );
      } else {
        router.push({
          path: "/exam/review/edit",
          query: {
            taskId: props.dataSource.taskId,
            detailId: props.dataSource.detailId,
            reexamId: props.dataSource.reexamId,
          },
        });
      }
    };

    return {
      dateFormat,
      getTaskType,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  &.card {
    .row.space-between {
      font-size: 16px !important;
      font-weight: 600;
      .mixinFlex(space-between; center);
      padding-top: 12px;
      border-top: 1px solid #f4f4f4;
    }
  }
}
</style>
